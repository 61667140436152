import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import ClutterImg from "../../assets/images/aliases/clutter.webp"
import ClutterMImg from "../../assets/images/aliases/clutter-m.webp"
import AliasesMImg from "../../assets/images/aliases/aliases-m.webp"
import YourPrivacyMImg from "../../assets/images/aliases/your-privacy-m.webp"
import AliasesTodayMImg from "../../assets/images/aliases/aliases-today-m.webp"
import { Link } from "gatsby"

const Aliases = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Aliases | BlueMail App"
        postDescription="With the Unified Inbox, syncing many accounts from many providers is not only simple, setting up is a snap. BlueMail automatically adds and configures  your IMAP, POP3, & Exchange accounts while also enabling lightning fast push services."
        postImage="/img/OG/og_image-BlueMail-Features-Aliases.png"
        postURL="features-functions/aliases"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid aliases w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Aliases</span>
                </h1>
                <p>
                  Aliases in email are a powerful tool that can help you stay
                  organized and keep your inbox from becoming cluttered.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={AliasesMImg} alt="Aliases" />
            </div>
          </div>
        </div>
        <div className="container-fluid your-privacy w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Enhancing</span> Your Privacy
                </h2>
                <p>
                  Aliases allow users to create unique addresses that don't
                  reveal any personal information or link back to a single user.
                  Having an alias lets you keep your primary address private
                  while still being able to receive emails from those who need
                  access without giving out your real address.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={YourPrivacyMImg} alt="Enhancing Your Privacy" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  Removing the <span>Clutter</span>
                </h2>
                <p>
                  With an alias, you can separate out different types of emails
                  so that they don't all end up in the same place. For example,
                  instead of having multiple accounts for work and personal use,
                  aliases allow you to easily differentiate between them without
                  needing to create completely separate accounts.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={ClutterImg}
                alt="Removing the Clutter"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={ClutterMImg}
                alt="Removing the Clutter mobile"
              />
            </div>
          </div>
        </div>
        <Link to="https://bluemail.me/help/add-multiple-aliases-per-account/">
          <div className="container-fluid aliases-today w-707 r mb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  <h2>
                    Start Using Aliases <span>Today</span>
                  </h2>
                  <p>
                    From talking to home buyers, selling your car, or looking
                    for a job, aliases are a perfect solution for when you may
                    want to use a temporary email address.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid w-991">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
                <img src={AliasesTodayMImg} alt="Start Using Aliases Today" />
              </div>
            </div>
          </div>
        </Link>
      </div>
      <Footer />
    </Layout>
  )
}

export default Aliases

export const query = graphql`
  query AliasesPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
