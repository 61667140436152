import React from "react"
import { Helmet } from "react-helmet"

const config = {
  siteName: "BlueMail",
  siteTitle:
    "BlueMail - The Best Email App for Android, iOS, Windows, Mac and Linux", //VV Site title.
  siteTitleShort: "Mail App", //VV Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt:
    "BlueMail - The Best Email App for Android, iOS, Windows, Mac and Linux", //VV Alternative site title for SEO.
  siteLogo: "https://bluemail.me/img/mini-logo.webp", //VV Logo used for SEO and manifest.
  siteImage: "https://bluemail.me/img/BlueMailBestImage.png",
  siteUrl: "https://bluemail.me", //VV Domain of your website without pathPrefix.
  siteDescription:
    "BlueMail is a modern powerful Email management tool with a sleek design, unified inbox and support for all your accounts: IMAP,Exchange,POP3.", // VV
  //siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "1425108201100352", //VV FB Application ID for using app insights
  googleAnalyticsID: "UA-63376517-7", //VV GA tracking ID.
  copyright: "©2022 Blix Inc.", // Copyright string for the footer of the website and RSS feed.
}

const SEO = props => {
  const { postTitle, postDescription, postImage, postURL } = props

  let title
  let description
  let image
  let url

  postTitle !== "" ? (title = postTitle) : (title = config.siteTitle)
  postDescription !== ""
    ? (description = postDescription)
    : (description = config.siteDescription)
  image = postImage ? `${config.siteUrl}${postImage}` : config.siteImage
  postURL !== ""
    ? (url = `${config.siteUrl}/${postURL}`)
    : (url = config.siteUrl)

  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "Organization",
    url: config.siteUrl,
    name: config.siteName,
    alternateName: config.siteTitle,
  }

  console.log(url)

  return (
    <div>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta http-equiv="content-language" content="en" />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:site_name" content={config.siteName} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={config.siteFBAppID} />
        <meta property="og:locale" content="en_US" />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@BlueMail" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@BlueMail" />
        

        {/* Canonical tag */}
        <link rel="canonical" href={url} />
      </Helmet>
    </div>
  )
}

export default SEO
